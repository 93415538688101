import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    selectProducts, 
    setProducts, 
    selectKits, 
    setKits,
    setTotalAmount, 
    selectHasWholesale,
    setHasWholesale,
    selectTotalAmountRetail,
    setTotalBreakdown,
    setTotalAmountWholesale,
    setTotalAmountRetail,
 } from '../../hooks/slices/counterSlice';
import { 
    Flex,
    Text,
    Image,
    IconButton,
    Input
} from '@chakra-ui/react';
import { MinusIcon } from '@chakra-ui/icons';
import { FaPlus } from "react-icons/fa";
import { capitalizeFirstLetter, formatterValue } from '../../resource/validate';
import { DeleteIcon } from '@chakra-ui/icons';

const ProductsMb = ({ product, setSumIva, setSumShopping, setSubTotalSum, setSumTotalOrde, idx }) => {
    const productsStore = useSelector(selectProducts);
    const kitsStore = useSelector(selectKits);
    const hasWholesale = useSelector(selectHasWholesale);
    const totalAmount = useSelector(selectTotalAmountRetail);
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        num: product.quantity
    });

    const changeNumProducts = (nums) => {
        if(nums !== ""){
            setValues({
                ...values,
                num: nums < 0 ? 0 : Number(nums)
            }) 
        }else{
            setValues({
                ...values,
                num: ""
            })
        }
    }

    useEffect(() => {
        if(values.num !== "") {
            let products_ = [...productsStore]
            let product_item = {...products_[idx]};
            product_item.unit_price =  parseFloat(product_item?.retail_price) * values.num > 15001 ||  parseFloat(totalAmount) > 15001 || hasWholesale ? product_item?.wholesale_price : product_item?.retail_price
            product_item.total_price = parseFloat(parseFloat(product_item?.retail_price) * values.num > 15001 ||  parseFloat(totalAmount) > 15001 ? product_item?.wholesale_price : product_item?.retail_price).toFixed(2) * values.num
            product_item.total_price_retail = parseFloat(product_item?.retail_price).toFixed(2) * values.num
            product_item.total_price_wholesale = parseFloat(product_item?.wholesale_price).toFixed(2) * values.num
            product_item.quantity = values.num
            product_item.total_printing = product_item?.printing?.price * values.num
            products_[idx] = product_item;
            //console.log(products_)
            dispatch(
                setProducts({products: products_ })
            );
        }
    }, [values]);

    const handleDelete = () => {
        const filterProductsShopping = productsStore.filter((it, indx) => indx !== idx);
        dispatch(
            setProducts({products: filterProductsShopping })
        );
        calculateTotals();
    }

    useEffect(() => {
        if(productsStore.length > 0){
            calculateTotals();
        }
    },[productsStore])

    useEffect(() => {
        handleChangePrices();
    },[hasWholesale])

    const handleChangePrices = () => {
        let products_ = [...productsStore]
        let kits_ = [...kitsStore]
        if(hasWholesale){
            products_.forEach((product, idx) => {
                let product_ = {...product}
                product_.unit_price = product_?.wholesale_price
                product_.total_price = product_?.wholesale_price * product_?.quantity
                products_[idx] = product_
            })
            kits_.forEach((kit, idx) => {
                let kit_ = {...kit}
                let total = kit_.unitary_wholesale_price * kit_.total_kits
                let discount = total * 0.05
                kit_.sub_sum_total_kit = total.toFixed(2);
                kit_.sum_total = total.toFixed(2);
                kit_.sum_total_kit = total.toFixed(2);
                kit_.sum_discount_kit = total - discount;
                kit_.discount_kit = discount;
                kit_.unitary_kit_price = kit_.unitary_wholesale_price
                kits_[idx] = kit_
            })
        }else{
            products_.forEach((product, idx) => {
                let product_ = {...product}
                product_.unit_price = product_?.retail_price
                product_.total_price = product_?.retail_price * product_?.quantity
                products_[idx] = product_
            })
            kits_.forEach((kit, idx) => {
                let kit_ = {...kit}
                let total = kit_.unitary_retail_price * kit_.total_kits
                let discount = total * 0.05
                kit_.sub_sum_total_kit = total.toFixed(2);
                kit_.sum_total = total.toFixed(2);
                kit_.sum_total_kit = total.toFixed(2);
                kit_.sum_discount_kit = total - discount;
                kit_.discount_kit = discount;
                kit_.unitary_kit_price = kit_.unitary_retail_price
                kits_[idx] = kit_
            })
        }
        dispatch(
            setProducts({products: [
                ...products_
            ]})
        )
        dispatch(
            setKits({kits: [
                ...kits_
            ]})
        )
    }


    const calculateTotals = () => {
        let subtotal_wholesale = 0
        let subtotal_retail = 0
        let iva_wholesale = 0
        let iva_retail = 0
        let shipping_wholesale = 199
        let shipping_retail = 199
        let total_printing = 0
        let total = 0
        let total_dicount_wholesale = 0
        let total_dicount_retail = 0

        productsStore.forEach(product_ => {
            subtotal_retail += parseFloat(product_.retail_price) * parseInt(product_.quantity)
            subtotal_wholesale += parseFloat(product_.wholesale_price) * parseInt(product_.quantity)
            total_printing += parseFloat(product_.printing.price) * parseInt(product_.quantity)
        })

        kitsStore.forEach(kit => {
            let total_kit_wholesale = 0;
            let total_kit_retail = 0;
            kit?.items?.forEach(item => {
                total_kit_wholesale += parseFloat(item?.wholesale_price) * parseInt(kit?.total_kits) 
                total_kit_retail += parseFloat(item?.retail_price) * parseInt(kit?.total_kits)
            })
            total_dicount_retail += total_kit_retail * 0.05
            total_dicount_wholesale += total_kit_wholesale * 0.05
            total_kit_retail = total_kit_retail - (total_kit_retail * 0.05)
            total_kit_wholesale = total_kit_wholesale - (total_kit_wholesale * 0.05)
            subtotal_retail += total_kit_retail
            subtotal_wholesale += total_kit_wholesale
        })

        subtotal_retail += total_printing
        subtotal_wholesale += total_printing
        iva_retail = subtotal_retail * 0.16
        iva_wholesale = subtotal_wholesale * 0.16
        let total_whitout_shipping_retail = subtotal_retail + iva_retail
        let total_whitout_shipping_wholesale = subtotal_wholesale + iva_wholesale
        
        if (total_whitout_shipping_retail <= 3000) {
            shipping_retail = 199;
        } else if (total_whitout_shipping_retail >= 3001 && total_whitout_shipping_retail <= 9999) {
            shipping_retail = 99;
        } else if (total_whitout_shipping_retail >= 10000) {
            shipping_retail = 0;
        }

        if (total_whitout_shipping_wholesale <= 3000) {
            shipping_wholesale = 199;
        } else if (total_whitout_shipping_wholesale >= 3001 && total_whitout_shipping_wholesale <= 9999) {
            shipping_wholesale = 99;
        } else if (total_whitout_shipping_wholesale >= 10000) {
            shipping_wholesale = 0;
        }

        if((total_whitout_shipping_retail + shipping_retail) > 15000){
            total = total_whitout_shipping_wholesale + shipping_wholesale
            const totalBreakdown ={
                subtotal: subtotal_wholesale,
                total_discount: 0,
                total_discount_kits: total_dicount_wholesale,
                total_iva: iva_wholesale,
                total_printing: total_printing,
                total_shipping: shipping_wholesale,
                total_amount: total,
            }
            dispatch(setTotalBreakdown({totalBreakdown}))
            dispatch(setHasWholesale({hasWholesale: true}))
        }else{
            total = total_whitout_shipping_retail + shipping_retail
            const totalBreakdown ={
                subtotal: subtotal_retail,
                total_discount: 0,
                total_discount_kits: total_dicount_retail,
                total_iva: iva_retail,
                total_printing: total_printing,
                total_shipping: shipping_retail,
                total_amount: total,
            }
            dispatch(setTotalBreakdown({totalBreakdown}))
            dispatch(setHasWholesale({hasWholesale: false}))
        }
        dispatch(setTotalAmountRetail({totalAmountRetail: (total_whitout_shipping_retail + shipping_retail)}))
        dispatch(setTotalAmountWholesale({totalAmountWholesale: (total_whitout_shipping_wholesale + shipping_wholesale)}))
        dispatch(setTotalAmount({totalAmount: total}))
    }

    /*const calculateSend = (sumTotalKit2) => {
        if (sumTotalKit2 <= 3000) {
            return 199;
        } else if (sumTotalKit2 >= 3000 && sumTotalKit2 <= 10000) {
            return 99;
        } else if (sumTotalKit2 > 10000) {
            return 0;
        }
    }

    useEffect(() => {
        if (newArray) {
            let sumP = 0;
            let sumK = 0;
            let sums = 0;
            let sumsIv = 0;
            let sumsSp = 0;
            if (newArray.length > 0) {
                newArray.forEach((elementP) => {
                    sumP = elementP.total_price + sumP;
                });
            }
            if (kitsStore.length > 0) {
                kitsStore.forEach((elementK) => {
                    sumK = elementK.sum_total_kit + sumK;
                });
            }
            sums = sumP + sumK;
            sumsIv = sums * 0.16;
            sumsSp = calculateSend(sums);
            if (sums > 0) {
                setSumIva(sumsIv);
                setSumShopping(sumsSp);
                setSubTotalSum(sums);
                setSumTotalOrder(sums + sumsIv + sumsSp);
                dispatch(
                    setProducts({products: newArray })
                );
                dispatch(
                    setTotalAmount({totalAmount: sums + sumsIv + sumsSp})
                );
            }
        }
    }, [newArray]);*/

    return ( 
        <Flex w='100%' h={"100%"} pt={2} pb={5} borderTop={"1px solid #E2E2E2"}>
            <Flex w='30%'>
                <Image src={product ? product.image : ''} width={"100%"} height={"80px"} alt='img' onClick={(e) => {e.preventDefault(); window.open('/producto/' + product?.product_sku, '_self');}}/>
            </Flex>
            <Flex w='70%' flexDirection={"column"} height={"100%"}>
                <Flex>
                    <Text fontSize='md' fontWeight={500} onClick={(e) => {e.preventDefault(); window.open('/producto/' + product?.product_sku, '_self');}}>{capitalizeFirstLetter(product ? product.name : '')}</Text>
                </Flex>
                <Flex alignItems={"end"} h={"100%"} gap={6} mt={2}>
                    <Text color={"#828282"} fontSize={"xs"} fontWeight={400}>Tipo de impresión:  {product?.printing?.type === 'serigrafia'? 'Serigrafía' : (product?.printing?.type === 'laser' ? 'Láser' : 'Sin impresión')}</Text>
                </Flex>
                <Flex height={"100%"} alignItems={"end"}>
                    <Flex w={"30%"}>
                        <Text color={"#212121"} fontSize={"xs"}>Cantidad: {product ? product.quantity : 0}</Text>
                    </Flex>
                    <Flex w={"70%"} flexDirection={"column"} justifyContent={"end"} alignItems={"end"}>
                        <Flex gap={6} alignSelf={"auto"} placeItems={'baseline'}>
                            <Text pt={3} color={"#212121"} fontWeight={500} fontSize={"md"}>{formatterValue((product && hasWholesale ? product.total_price_wholesale : product.total_price_retail) + product?.total_printing)}</Text>
                            <DeleteIcon onClick={() => handleDelete()} color='red' cursor={"pointer"} _hover={{color: 'red.500'}}/>
                        </Flex>
                        <Flex pt={3} alignItems={'center'}>
                            <IconButton
                                w={"10px"} h={"28px"}
                                bg={"#D0D0D2"}
                                onClick={() => changeNumProducts(values.num - 1)}
                                boxShadow={"rgb(221, 221, 221) 0px 4px 8px 0px"}
                                color={"#383838"}
                                fontSize={"12px"}
                                icon={<MinusIcon />} />
                            
                            {/*<Text ml={2} mr={2} mb={2} color={"#828282"} fontSize={"16px"} fontWeight={400}>{values.num}</Text>*/}
                            <Input 
                                ml={2} 
                                mr={2}
                                color={"#828282"} 
                                fontSize={"16px"} 
                                fontWeight={400}
                                value={values.num}
                                type='number'
                                min={0}
                                maxW={'80px'}
                                onChange={(e)=>{
                                    changeNumProducts(e.target.value)
                                }}
                            />
                            <IconButton
                                w={"10px"} h={"28px"}
                                bg='#31508C'
                                onClick={() => changeNumProducts(values.num + 1)}
                                boxShadow={"rgb(221, 221, 221) 0px 4px 8px 0px"}
                                color={"#FFF"}
                                fontSize={"12px"}
                                _hover={{
                                    bg: '#24437E'
                                }}
                                icon={<FaPlus />} />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
 
export default ProductsMb;